// src/components/HomePage.js
import React from 'react';
import HeroSection from './HeroSection';
import TaglineSection from './TaglineSection';
import VehicleSlider from './VehicleSlider';
import ServicesSection from './ServicesSection';
import LargeBanner from './LargeBanner';
import BlankSpace from './BlankSpace';

function HomePage() {
  return (
    <>
      <HeroSection />
      <TaglineSection />
      <LargeBanner />
      <BlankSpace />
      <VehicleSlider />
      <ServicesSection />
    </>
  );
}

export default HomePage;
