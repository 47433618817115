// src/components/BookingPage.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/BookingPage.css';
import Header from './Header';
import NavigationMenu from './NavigationMenu';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';


function BookingPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedVehicle = queryParams.get('vehicle') || '';
  emailjs.init('3y6DH0dNE6wBaTbBO'); // Replace with your actual Public Key
  const [formData, setFormData] = useState({
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    vehicle: '',
    name: '',
    email: '',
    phone: '',
    extras: [],
    additionalInfo: '',
  });

  const vehicles = [
    'Mercedes Sprinter',
    'Mercedes Maybach',
    'Range Rover',
    'Cadillac Escalade',
    'GMC Yukon Denali',
    'Chevy Suburban',
  ];

  const extraServices = [
    'Armed Security',
    'Personal Chauffeur',
    'Beverage Service',
    'Event Coordination',
    'Tours',
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox' && name === 'extras') {
      setFormData((prevData) => {
        if (checked) {
          return { ...prevData, extras: [...prevData.extras, value] };
        } else {
          return { ...prevData, extras: prevData.extras.filter((extra) => extra !== value) };
        }
      });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

const handleSubmit = (e) => {
  e.preventDefault();

  // Prepare the template parameters
  const templateParams = {
    startDate: formData.startDate,
    startTime: formData.startTime,
    endDate: formData.endDate,
    endTime: formData.endTime,
    vehicle: formData.vehicle,
    name: formData.name,
    email: formData.email,
    phone: formData.phone,
    extras: formData.extras.join(', ') || 'None',
    additionalInfo: formData.additionalInfo || 'None',
  };

  emailjs
    .send('service_r6tz8l9', 'template_gwtugzl', templateParams)
    .then(
      (response) => {
        console.log('Email sent successfully!', response.status, response.text);
        alert('Your booking request has been submitted successfully!');
        // Optionally, reset the form
        setFormData({
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          vehicle: '',
          name: '',
          email: '',
          phone: '',
          extras: [],
          additionalInfo: '',
        });
      },
      (error) => {
        console.error('Failed to send email:', error);
        alert('There was an error submitting your booking request. Please try again later.');
      }
    );
};



  return (
    <>
      <div className="booking-page">
        <h1 className="booking-title">Book Your Luxury Ride</h1>
        <form className="booking-form" onSubmit={handleSubmit}>
          {/* Date and Time Selection */}
          <fieldset className="form-section">
            <legend>Trip Details</legend>
            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="startTime">Start Time</label>
              <input
                type="time"
                id="startTime"
                name="startTime"
                value={formData.startTime}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endTime">End Time</label>
              <input
                type="time"
                id="endTime"
                name="endTime"
                value={formData.endTime}
                onChange={handleChange}
                required
              />
            </div>
          </fieldset>

          {/* Vehicle Selection */}
          <fieldset className="form-section">
            <legend>Vehicle Selection</legend>
            <div className="form-group">
              <label htmlFor="vehicle">Choose Your Vehicle</label>
              <select
                id="vehicle"
                name="vehicle"
                value={formData.vehicle}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  -- Select a Vehicle --
                </option>
                {vehicles.map((vehicle, index) => (
                  <option key={index} value={vehicle}>
                    {vehicle}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>

          {/* Renter Information */}
          <fieldset className="form-section">
            <legend>Personal Information</legend>
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Your Full Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Your Email Address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Your Phone Number"
              />
            </div>
          </fieldset>

          {/* Extra Services */}
          <fieldset className="form-section">
            <legend>Extra Services</legend>
            <div className="extras-container">
              {extraServices.map((service, index) => (
                <div key={index} className="form-group checkbox-group">
                  <input
                    type="checkbox"
                    id={`extra-${index}`}
                    name="extras"
                    value={service}
                    onChange={handleChange}
                  />
                  <label htmlFor={`extra-${index}`}>{service}</label>
                </div>
              ))}
            </div>
          </fieldset>

          {/* Additional Information */}
          <fieldset className="form-section">
            <legend>Additional Information</legend>
            <div className="form-group">
              <label htmlFor="additionalInfo">Special Requests or Notes</label>
              <textarea
                id="additionalInfo"
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
                placeholder="Let us know if you have any special requests..."
              ></textarea>
            </div>
          </fieldset>

          {/* Submit Button */}
          <button type="submit" className="submit-button">
            Submit Booking Request
          </button>
        </form>
      </div>
    </>
  );
}

export default BookingPage;
