// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/GlobalStyles.css';
import Header from './components/Header';
import NavigationMenu from './components/NavigationMenu';
import HomePage from './components/HomePage';
import BookingPage from './components/BookingPage';
import ChauffeursPage from './components/ChauffeursPage';
import ServicesPage from './components/ServicesPage';
import Footer from './components/Footer';

// Import the vehicle pages
import MercedesSprinter from './pages/MercedesSprinter';
import MercedesMaybach from './pages/MercedesMaybach';
import RangeRover from './pages/RangeRover';
import CadillacEscalade from './pages/CadillacEscalade';
import GMCYukonDenali from './pages/GMCYukonDenali';
import ChevySuburban from './pages/ChevySuburban';

function App() {
  return (
    <Router>
      <NavigationMenu />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/booking" element={<BookingPage />} />
		<Route path="/chauffeurs" element={<ChauffeursPage />} />
		<Route path="/services" element={<ServicesPage />} />
		{/* Vehicle Pages */}
        <Route path="/vehicles/mercedes-sprinter" element={<MercedesSprinter />} />
        <Route path="/vehicles/mercedes-maybach" element={<MercedesMaybach />} />
        <Route path="/vehicles/range-rover" element={<RangeRover />} />
        <Route path="/vehicles/cadillac-escalade" element={<CadillacEscalade />} />
        <Route path="/vehicles/gmc-yukon-denali" element={<GMCYukonDenali />} />
        <Route path="/vehicles/chevy-suburban" element={<ChevySuburban />} />
        {/* Add other routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
