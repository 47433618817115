// src/components/Footer.js
import React from 'react';
import '../styles/Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'; // Optional: Social Media Icons

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h4>Company</h4>
          <a href="/about">About Us</a>
          <a href="/careers">Careers</a>
          <a href="/press">Press</a>
          <a href="/investors">Investors</a>
        </div>
        <div className="footer-column">
          <h4>Services</h4>
          <a href="/booking">Book a Ride</a>
          <a href="/fleet">Fleet</a>
          <a href="/chauffeurs">Chauffeurs</a>
          <a href="/support">Customer Support</a>
        </div>
        <div className="footer-column">
          <h4>Legal</h4>
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/cookies">Cookie Policy</a>
        </div>
        <div className="footer-column">
          <h4>Contact Us</h4>
          <p>Address: PO BOX 190334, Dallas, TX 75219</p>
          <p>Phone: <a href="tel:2149842522">(214) 984-2522</a></p>
          <p>Email: <a href="mailto:ride@tacttransport.com">ride@tacttransport.com</a></p>
          <p>Service Area: Texas, Louisiana, Oklahoma.</p>
          {/* Optional: Social Media Links */}
          <div className="social-icons">
            <a href="https://facebook.com" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://twitter.com" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://instagram.com" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://linkedin.com" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        © 2024 Tact Transport. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
