import React from 'react';
import '../styles/ServicesSection.css';
import { FaBuilding, FaPlane, FaGraduationCap, FaFootballBall, FaCarSide } from 'react-icons/fa';

function ServicesSection() {
  const services = [
    {
      title: 'Corporate Travel',
      icon: <FaBuilding />,
      description: 'Experience unparalleled professionalism with our bespoke transportation solutions tailored for discerning business leaders.',
      link: '/services',
    },
    {
      title: 'Airport Transfers',
      icon: <FaPlane />,
      description: 'Arrive and depart with elegance. Our punctual and luxurious airport transfers redefine your travel experience.',
      link: '/services',
    },
    {
      title: 'Prom Chauffeur',
      icon: <FaGraduationCap />,
      description: 'Make a grand entrance with our premium prom chauffeur services, ensuring a night of unforgettable elegance and style.',
      link: '/services',
    },
    {
      title: 'Sporting Events',
      icon: <FaFootballBall />,
      description: 'Enjoy VIP treatment with our exclusive transportation services to your most anticipated sporting events.',
      link: '/services',
    },
    {
      title: 'Private Chauffeur',
      icon: <FaCarSide />,
      description: 'Indulge in personalized chauffeur services, offering privacy, comfort, and sophistication for any occasion.',
      link: '/services',
    },
  ];

  return (
    <section className="services-section" aria-labelledby="services-title">
      <h2 id="services-title" className="services-title">Our Exclusive Services</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <a href={service.link} className="service-item" key={index} aria-label={service.title}>
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </a>
        ))}
      </div>
    </section>
  );
}

export default ServicesSection;
