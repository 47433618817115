// src/components/LargeBanner.js
import React from 'react';
import '../styles/LargeBanner.css';
import backgroundImage from '../assets/fleet-wide.jpg'; // Ensure you have this image in src/assets/

function LargeBanner() {
  return (
    <section
      className="large-banner"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="quote-container">
        <p className="banner-quote">
          “When I’m off the field, they’re my go-to for a smooth ride. Always first-class.” - Ezekiel Elliott
        </p>
      </div>
    </section>
  );
}

export default LargeBanner;