import React from 'react';
import '../styles/VehiclePage.css';
import sprinterMain from '../assets/vehicles/mercedes-sprinter/sprinter-main.jpg';
import sprinter1 from '../assets/vehicles/mercedes-sprinter/sprinter-1.jpg';
import sprinter2 from '../assets/vehicles/mercedes-sprinter/sprinter-2.jpg';
import sprinter3 from '../assets/vehicles/mercedes-sprinter/sprinter-3.jpg';
import sprinter4 from '../assets/vehicles/mercedes-sprinter/sprinter-4.jpg';

function MercedesSprinter() {
  return (
    <div className="vehicle-page">
      <h1 className="vehicle-title">Mercedes-Benz Sprinter Van</h1>
      <img src={sprinterMain} alt="Mercedes Sprinter Van" className="vehicle-image" />
      <div className="vehicle-content">
        <p>
          Experience the epitome of luxury and comfort with our Mercedes-Benz Sprinter Van.
          Tailored for VIPs and discerning clients, this vehicle offers a limousine-style
          interior that redefines group travel. With seating for up to <strong>9 passengers</strong>,
          it's perfect for corporate events, special occasions, or a night out in style.
        </p>
        <h2>Features</h2>
        <ul>
          <li>Luxurious leather seating with ample legroom</li>
          <li>High-definition TVs and premium sound system</li>
          <li>Ambient lighting to set the perfect mood</li>
          <li>Privacy partitions and tinted windows</li>
          <li>On-board Wi-Fi connectivity</li>
        </ul>
        <h2>Why Choose the Mercedes Sprinter Van?</h2>
        <p>
          The Mercedes Sprinter Van transforms every journey into a VIP experience.
          Its limousine-style interior ensures that you and your guests travel in
          unparalleled comfort and style. Whether it's for business or leisure,
          make a statement with the Sprinter Van.
        </p>
        <div className="vehicle-gallery">
          <h2>Gallery</h2>
          <div className="gallery-images">
            <div className="gallery-item">
              <img src={sprinter1} alt="Sprinter Interior 1" />
            </div>
            <div className="gallery-item">
              <img src={sprinter2} alt="Sprinter Interior 2" />
            </div>
            <div className="gallery-item">
              <img src={sprinter3} alt="Sprinter Interior 3" />
            </div>
            <div className="gallery-item">
              <img src={sprinter4} alt="Sprinter Interior 4" />
            </div>
          </div>
        </div>
        <div className="book-now-container">
          <a href="/booking?vehicle=Mercedes%20Sprinter" className="book-now-button2">
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default MercedesSprinter;
