// src/pages/RangeRover.js
import React from 'react';
import '../styles/VehiclePage.css';
import rangeRoverMain from '../assets/vehicles/range-rover/range-rover-main.jpg';
import rangeRover1 from '../assets/vehicles/range-rover/range-rover-1.jpg';
import rangeRover2 from '../assets/vehicles/range-rover/range-rover-2.jpg';
import rangeRover3 from '../assets/vehicles/range-rover/range-rover-3.jpg';
import rangeRover4 from '../assets/vehicles/range-rover/range-rover-4.jpg';

function RangeRover() {
  return (
    <div className="vehicle-page">
      <h1 className="vehicle-title">Land Rover Range Rover</h1>
      <img src={rangeRoverMain} alt="Land Rover Range Rover" className="vehicle-image" />
      <div className="vehicle-content">
        <p>
          The Land Rover Range Rover seamlessly blends luxury with performance.
          With seating for up to <strong>3 passengers</strong>, it offers a refined
          experience for those who appreciate sophistication and capability.
        </p>
        <h2>Features</h2>
        <ul>
          <li>Sumptuous leather interiors with heated seats</li>
          <li>Adaptive suspension for a smooth ride</li>
          <li>Premium Meridian™ sound system</li>
          <li>All-terrain capability with advanced traction control</li>
          <li>Panoramic sunroof and ambient interior lighting</li>
        </ul>
        <h2>Why Choose the Range Rover?</h2>
        <p>
          For clients who desire both luxury and the ability to go anywhere,
          the Range Rover is unparalleled. It's perfect for city travel or
          off-the-beaten-path adventures, all while enveloped in comfort.
        </p>
        <div className="vehicle-gallery">
          <h2>Gallery</h2>
          <div className="gallery-images">
            <div className="gallery-item">
              <img src={rangeRover1} alt="Range Rover Interior 1" />
            </div>
            <div className="gallery-item">
              <img src={rangeRover2} alt="Range Rover Interior 2" />
            </div>
            <div className="gallery-item">
              <img src={rangeRover3} alt="Range Rover Interior 3" />
            </div>
            <div className="gallery-item">
              <img src={rangeRover4} alt="Range Rover Interior 4" />
            </div>
            {/* Add more images as needed */}
          </div>
        </div>
        <div className="book-now-container">
          <a href="/booking?vehicle=Range%20Rover" className="book-now-button2">
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default RangeRover;
