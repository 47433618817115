// src/components/VehicleSlider.js
import React, { useState, useEffect, useRef } from 'react';
import '../styles/VehicleSlider.css';
import mercedesSprinter from '../assets/sprinter.png';
import mercedesMaybach from '../assets/maybach.png';
import rangeRover from '../assets/rangerover.png';
import cadillacEscalade from '../assets/escalade.png';
import gmcYukon from '../assets/yukon.png';
import chevySuburban from '../assets/suburban.png';

function VehicleSlider() {
  // Define vehicle data with detailed features
  const vehicles = [
    {
      id: 1,
      title: 'Mercedes Sprinter',
      img: mercedesSprinter,
      alt: 'Mercedes Sprinter',
      features: [
        'Luxurious leather seating',
        'Advanced climate control systems',
        'State-of-the-art navigation and entertainment',
        'High safety standards with multiple airbags',
        'Spacious cargo area',
      ],
    },
    {
      id: 2,
      title: 'Mercedes Maybach',
      img: mercedesMaybach,
      alt: 'Mercedes Maybach Gas',
      features: [
        'Ultra-luxurious interiors with premium materials',
        'Exclusive infotainment system',
        'Advanced driver-assistance systems',
        'Personalized climate control',
        'Rear-seat entertainment features',
      ],
    },
    {
      id: 3,
      title: 'Range Rover',
      img: rangeRover,
      alt: 'Range Rover',
      features: [
        'Superior off-road capabilities',
        'Elegant and spacious interiors',
        'Advanced suspension systems',
        'Cutting-edge safety features',
        'Premium audio systems',
      ],
    },
    {
      id: 4,
      title: 'Cadillac Escalade',
      img: cadillacEscalade,
      alt: 'Cadillac Escalade',
      features: [
        'Bold and commanding presence',
        'Spacious seating for up to eight passengers',
        'Advanced safety and driver assistance features',
        'High-end infotainment and connectivity options',
        'Powerful V8 engine',
      ],
    },
    {
      id: 5,
      title: 'GMC Yukon Denali',
      img: gmcYukon,
      alt: 'GMC Yukon Denali',
      features: [
        'Premium interior with luxurious materials',
        'Advanced technology and infotainment systems',
        'Robust performance with V8 engine',
        'Spacious seating and cargo space',
        'Enhanced safety features',
      ],
    },
    {
      id: 6,
      title: 'Chevy Suburban',
      img: chevySuburban,
      alt: 'Chevy Suburban',
      features: [
        'Extensive passenger and cargo space',
        'Advanced safety and connectivity features',
        'Powerful engine options',
        'Comfortable and refined interior',
        'Versatile seating configurations',
      ],
    },
  ];

  const [openOverlays, setOpenOverlays] = useState([]); // Track open overlays
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const autoScrollInterval = useRef(null);

  // Function to toggle overlay
  const toggleOverlay = (id) => {
    setOpenOverlays((prev) =>
      prev.includes(id) ? prev.filter((overlayId) => overlayId !== id) : [...prev, id]
    );
  };

  // Auto-scroll function using setInterval
  const startAutoScroll = () => {
    if (autoScrollInterval.current) return; // Prevent multiple intervals

    autoScrollInterval.current = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.scrollLeft += 1; // Adjust scroll speed here
        // Reset scroll for infinite effect
        if (sliderRef.current.scrollLeft >= sliderRef.current.scrollWidth / 2) {
          sliderRef.current.scrollLeft = 0;
        }
      }
    }, 20); // Adjust interval for smoothness
  };

  const stopAutoScroll = () => {
    if (autoScrollInterval.current) {
      clearInterval(autoScrollInterval.current);
      autoScrollInterval.current = null;
    }
  };

  // Initialize auto-scroll
  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll(); // Cleanup on unmount
  }, []);

  // Handle mouse and touch events for dragging
  const handleMouseDown = (e) => {
    isDragging.current = true;
    const slider = sliderRef.current;
    const sliderRect = slider.getBoundingClientRect();
    startX.current = e.clientX - sliderRect.left;
    scrollLeft.current = slider.scrollLeft;
    stopAutoScroll(); // Pause auto-scroll on interaction
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    if (isDragging.current) {
      isDragging.current = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      // Delay auto-scroll resume by 2 seconds
      setTimeout(() => {
        startAutoScroll();
      }, 2000);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const slider = sliderRef.current;
    const sliderRect = slider.getBoundingClientRect();
    const x = e.clientX - sliderRect.left;
    const walk = (x - startX.current) * 1.5; // Adjust scroll speed multiplier
    slider.scrollLeft = scrollLeft.current - walk;
  };

  // Define handleMouseLeaveDrag
  const handleMouseLeaveDrag = () => {
    if (isDragging.current) {
      handleMouseUp();
    }
  };

  // Touch events remain the same
  const handleTouchStart = (e) => {
    isDragging.current = true;
    const slider = sliderRef.current;
    const sliderRect = slider.getBoundingClientRect();
    startX.current = e.touches[0].clientX - sliderRect.left;
    scrollLeft.current = slider.scrollLeft;
    stopAutoScroll(); // Pause auto-scroll on interaction
  };

  const handleTouchEnd = () => {
    if (isDragging.current) {
      isDragging.current = false;
      setTimeout(() => {
        startAutoScroll();
      }, 2000);
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;
    const slider = sliderRef.current;
    const sliderRect = slider.getBoundingClientRect();
    const x = e.touches[0].clientX - sliderRect.left;
    const walk = (x - startX.current) * 1.5; // Adjust scroll speed multiplier
    slider.scrollLeft = scrollLeft.current - walk;
  };

  // Handle mouse movement to adjust auto-scroll speed based on mouse position
  const handleMouseMoveSpeed = (e) => {
    const slider = sliderRef.current;
    if (!slider) return;

    const rect = slider.getBoundingClientRect();
    const mouseX = e.clientX - rect.left; // Mouse position within slider
    const percentage = mouseX / rect.width; // 0 (left) to 1 (right)

    // Set speed: faster at edges, slower in center
    if (percentage < 0.25 || percentage > 0.75) {
      // Faster scroll at edges
      slider.scrollLeft += 1.5;
    } else {
      // Slower scroll in center
      slider.scrollLeft += 0.5;
    }
  };

  // Prevent background scrolling when overlays are open
  useEffect(() => {
    if (openOverlays.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [openOverlays]);

  // Handle overlay click outside content to close
  const handleOverlayClick = (e, id) => {
    if (e.target.classList.contains('overlay')) {
      toggleOverlay(id);
    }
  };

  return (
    <section
      className="vehicle-slider"
      onMouseEnter={stopAutoScroll}
      onMouseLeave={startAutoScroll}
      onTouchStart={stopAutoScroll}
      onTouchEnd={startAutoScroll}
    >
      <div
        className="slider-track"
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeaveDrag}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {vehicles.concat(vehicles).map((vehicle, index) => (
          <div className="slide" key={index}>
            <img
              src={vehicle.img}
              alt={vehicle.alt}
              onClick={() => toggleOverlay(vehicle.id)}
              loading="lazy"
            />
            {/* Overlay */}
            {openOverlays.includes(vehicle.id) && (
              <div
                className="overlay"
                role="dialog"
                aria-modal="true"
                onClick={(e) => handleOverlayClick(e, vehicle.id)}
              >
                <button
                  className="close-button"
                  onClick={() => toggleOverlay(vehicle.id)}
                  aria-label="Close Overlay"
                >
                  &times;
                </button>
                <h3>{vehicle.title}</h3>
                <ul>
                  {vehicle.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
                {/* Add the Book Now button */}
                <div className="book-now-container">
                  <a href={`/booking?vehicle=${encodeURIComponent(vehicle.title)}`} className="book-now-button">
                    Book Now
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default VehicleSlider;
