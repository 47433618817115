// src/pages/MercedesMaybach.js
import React from 'react';
import '../styles/VehiclePage.css';
import maybachMain from '../assets/vehicles/mercedes-maybach/maybach-main.jpg';
import maybach1 from '../assets/vehicles/mercedes-maybach/maybach-1.jpg';
import maybach2 from '../assets/vehicles/mercedes-maybach/maybach-2.jpg';
import maybach3 from '../assets/vehicles/mercedes-maybach/maybach-3.jpg';
import maybach4 from '../assets/vehicles/mercedes-maybach/maybach-4.jpg';


function MercedesMaybach() {
  return (
    <div className="vehicle-page">
      <h1 className="vehicle-title">Mercedes-Maybach GLS</h1>
      <img src={maybachMain} alt="Mercedes-Maybach GLS" className="vehicle-image" />
      <div className="vehicle-content">
        <p>
          The Mercedes-Maybach GLS is the pinnacle of automotive luxury. With its
          exclusive design and opulent features, it offers an unmatched experience
          for the elite. Seating up to <strong>3 passengers</strong>, it's the perfect
          choice for VIPs seeking privacy and comfort.
        </p>
        <h2>Features</h2>
        <ul>
          <li>Ultra-luxurious Nappa leather seating</li>
          <li>Rear-seat entertainment with individual screens</li>
          <li>Panoramic sunroof with ambient lighting</li>
          <li>Advanced climate control with fragrance options</li>
          <li>Cutting-edge driver assistance and safety systems</li>
        </ul>
        <h2>Why Choose the Mercedes-Maybach GLS?</h2>
        <p>
          The Mercedes-Maybach GLS is more than a vehicle; it's a sanctuary on wheels.
          Ideal for celebrities, executives, and those who demand the very best,
          it ensures that every journey is an indulgent experience.
        </p>
        <div className="vehicle-gallery">
          <h2>Gallery</h2>
          <div className="gallery-images">
            <div className="gallery-item">
              <img src={maybach1} alt="Maybach Interior 1" />
            </div>
            <div className="gallery-item">
              <img src={maybach2} alt="Maybach Interior 2" />
            </div>
            <div className="gallery-item">
              <img src={maybach3} alt="Maybach Interior 3" />
            </div>
            <div className="gallery-item">
              <img src={maybach4} alt="Maybach Interior 4" />
            </div>
            {/* Add more images as needed */}
          </div>
        </div>
        <div className="book-now-container">
          <a href="/booking?vehicle=Mercedes%20Maybach" className="book-now-button2">
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default MercedesMaybach;
