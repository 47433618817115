// src/pages/ChevySuburban.js
import React from 'react';
import '../styles/VehiclePage.css';
import suburbanMain from '../assets/vehicles/chevy-suburban/suburban-main.jpg';
import suburban1 from '../assets/vehicles/chevy-suburban/suburban-1.jpg';
import suburban2 from '../assets/vehicles/chevy-suburban/suburban-2.jpg';
import suburban3 from '../assets/vehicles/chevy-suburban/suburban-3.jpg';
import suburban4 from '../assets/vehicles/chevy-suburban/suburban-4.jpg';

function ChevySuburban() {
  return (
    <div className="vehicle-page">
      <h1 className="vehicle-title">Chevrolet Suburban</h1>
      <img src={suburbanMain} alt="Chevrolet Suburban" className="vehicle-image" />
      <div className="vehicle-content">
        <p>
          The Chevrolet Suburban is an icon of spacious comfort and reliability.
          Accommodating up to <strong>6 passengers</strong>, it's perfect for
          those who need ample space for passengers and luggage.
        </p>
        <h2>Features</h2>
        <ul>
          <li>Comfortable seating with tri-zone climate control</li>
          <li>Advanced safety features including lane assist</li>
          <li>Infotainment system with Apple CarPlay® and Android Auto™</li>
          <li>Exceptional cargo capacity for luggage</li>
          <li>Smooth ride with premium suspension</li>
        </ul>
        <h2>Why Choose the Chevrolet Suburban?</h2>
        <p>
          The Suburban is ideal for family travel, airport transfers, and group outings.
          Its combination of comfort, space, and modern amenities makes every trip
          enjoyable and hassle-free.
        </p>
        <div className="vehicle-gallery">
          <h2>Gallery</h2>
          <div className="gallery-images">
            <div className="gallery-item">
              <img src={suburban1} alt="Suburban Interior 1" />
            </div>
            <div className="gallery-item">
              <img src={suburban2} alt="Suburban Interior 2" />
            </div>
            <div className="gallery-item">
              <img src={suburban3} alt="Suburban Interior 3" />
            </div>
            <div className="gallery-item">
              <img src={suburban4} alt="Suburban Interior 4" />
            </div>
            {/* Add more images as needed */}
          </div>
        </div>
        <div className="book-now-container">
          <a href="/booking?vehicle=Chevy%20Suburban" className="book-now-button2">
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default ChevySuburban;
