// src/components/TaglineSection.js
import React from 'react';
import '../styles/TaglineSection.css';

function TaglineSection() {
  return (
    <section className="tagline-section">
      <div className="tagline-container">
        <h2 className="tagline">Book now, and ride like a champion</h2>
        <a href="/booking" className="book-now-btn">Book Now</a>
      </div>
    </section>
  );
}

export default TaglineSection;
