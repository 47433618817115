// src/components/HeroSection.js
import React, { useState, useRef } from 'react';
import '../styles/HeroSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
// Removed unused logo import to fix ESLint warning
// import logo from '../assets/logo.png'; 

function HeroSection() {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // Video starts muted

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <section className="hero-section">
      <video
        className="hero-video"
        autoPlay
        loop
        muted={isMuted}
        ref={videoRef}
        playsInline // Ensures the video plays inline on mobile devices
      >
        <source src="/videos/hero-video.mp4" type="video/mp4" />
        <source src="/videos/hero-video.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-overlay"></div>
      {/* Optional: Add logo if needed */}
      {/* <img src={logo} alt="Logo" className="hero-logo" /> */}
      
      {/* Mute/Unmute Button */}
      <button
        className="mute-button"
        onClick={toggleMute}
        aria-label={isMuted ? 'Unmute video' : 'Mute video'}
      >
        <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} size="lg" />
      </button>
    </section>
  );
}

export default HeroSection;