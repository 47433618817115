// src/components/ChauffeursPage.js
import React from 'react';
import '../styles/ChauffeursPage.css';
import placeholderImage from '../assets/placeholder.jpg'; // Add a placeholder image to your assets folder

function ChauffeursPage() {
  const chauffeurs = [
    {
      name: 'John Smith',
      experience: '10 years',
      languages: 'English, Spanish',
      specialties: 'Corporate Travel, Special Events',
      bio: 'John has been with Tact Transport for over a decade, providing exceptional service to high-profile clients. He specializes in corporate travel and is known for his professionalism and reliability.',
    },
    {
      name: 'Maria Johnson',
      experience: '8 years',
      languages: 'English, French',
      specialties: 'Airport Transfers, Private Tours',
      bio: 'Maria’s attention to detail and knowledge of the city make her the perfect choice for airport transfers and private tours. Her friendly demeanor ensures a comfortable and enjoyable ride.',
    },
    {
      name: 'Michael Williams',
      experience: '12 years',
      languages: 'English, Italian',
      specialties: 'VIP Clients, Prom Chauffeur',
      bio: 'Michael is known for his impeccable service and ability to handle any situation with grace. Whether it’s chauffeuring VIP clients or making a prom night special, he goes above and beyond.',
    },
    {
      name: 'Sofia Brown',
      experience: '6 years',
      languages: 'English, German',
      specialties: 'Luxury Travel, Sporting Events',
      bio: 'Sofia brings a wealth of experience in luxury transportation, ensuring that every client enjoys a first-class experience, whether they are attending a sporting event or a gala.',
    },
    {
      name: 'James Davis',
      experience: '15 years',
      languages: 'English, Mandarin',
      specialties: 'Long-Distance Travel, Tours',
      bio: 'With extensive experience in long-distance travel, James is the ideal chauffeur for road trips and tours. His fluency in Mandarin allows him to assist international clients comfortably.',
    },
    {
      name: 'Emma Martinez',
      experience: '9 years',
      languages: 'English, Portuguese',
      specialties: 'Special Events, Private Chauffeur',
      bio: 'Emma’s expertise in special event transportation has made her a favorite among clients looking for a reliable and professional private chauffeur. She ensures that every ride is seamless and enjoyable.',
    },
  ];

  return (
    <div className="chauffeurs-page">
      <h1 className="services-page-title">Chauffeurs</h1>
      <div className="chauffeurs-list">
        {chauffeurs.map((chauffeur, index) => (
          <div
            key={index}
            className={`chauffeur-card ${index % 2 === 0 ? 'left-image' : 'right-image'}`}
          >
            <img src={placeholderImage} alt={`${chauffeur.name}`} className="chauffeur-image" />
            <div className="chauffeur-info">
              <h2>{chauffeur.name}</h2>
              <p><strong>Experience:</strong> {chauffeur.experience}</p>
              <p><strong>Languages Spoken:</strong> {chauffeur.languages}</p>
              <p><strong>Specialties:</strong> {chauffeur.specialties}</p>
              <p>{chauffeur.bio}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChauffeursPage;
