// src/components/Header.js
import React from 'react';
import '../styles/Header.css';
import logo from '../assets/logo.png'; // Ensure logo.png is in src/assets/

function Header() {
  return (
    <header className="header">
      <div className="header-container">
        <img src={logo} alt="Tact Transport Logo" className="logo" />
        <a href="/booking" className="book-now-button">Book Now</a>
      </div>
    </header>
  );
}

export default Header;
