// src/components/NavigationMenu.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/NavigationMenu.css';
import { FaBars, FaTimes } from 'react-icons/fa';

function NavigationMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [fleetDropdownOpen, setFleetDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      setFleetDropdownOpen(false); // Close dropdown when menu is closed
    }
  };

  const toggleFleetDropdown = () => {
    setFleetDropdownOpen(!fleetDropdownOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setFleetDropdownOpen(false);
  };

  return (
    <nav className="navigation-menu">
      <div className="info-left">
        <a href="mailto:ride@tacttransport.com">ride@tacttransport.com</a>
      </div>
      <div className="nav-container">
        <button 
          className="nav-icon" 
          onClick={toggleMenu}
          aria-label={menuOpen ? 'Close Menu' : 'Open Menu'}
          aria-expanded={menuOpen}
        >
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <li>
            <NavLink 
              to="/" 
              className={({ isActive }) => isActive ? 'active-link' : ''}
              onClick={closeMenu}
            >
              HOME
            </NavLink>
          </li>
          <li 
            className={`dropdown ${fleetDropdownOpen ? 'open' : ''}`}
            onMouseEnter={() => setFleetDropdownOpen(true)}
            onMouseLeave={() => setFleetDropdownOpen(false)}
          >
            <button 
              className="dropdown-toggle" 
              onClick={toggleFleetDropdown}
              aria-haspopup="true" 
              aria-expanded={fleetDropdownOpen}
            >
              FLEET
            </button>
            <ul className={`dropdown-menu ${fleetDropdownOpen ? 'show' : ''}`}>
              <li>
                <NavLink to="/vehicles/mercedes-sprinter" onClick={closeMenu}>
                  Mercedes Sprinter
                </NavLink>
              </li>
              <li>
                <NavLink to="/vehicles/mercedes-maybach" onClick={closeMenu}>
                  Mercedes Maybach Gas
                </NavLink>
              </li>
              <li>
                <NavLink to="/vehicles/range-rover" onClick={closeMenu}>
                  Range Rover
                </NavLink>
              </li>
              <li>
                <NavLink to="/vehicles/cadillac-escalade" onClick={closeMenu}>
                  Cadillac Escalade
                </NavLink>
              </li>
              <li>
                <NavLink to="/vehicles/gmc-yukon-denali" onClick={closeMenu}>
                  GMC Yukon Denali
                </NavLink>
              </li>
              <li>
                <NavLink to="/vehicles/chevy-suburban" onClick={closeMenu}>
                  Chevy Suburbans
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink 
              to="/services" 
              className={({ isActive }) => isActive ? 'active-link' : ''}
              onClick={closeMenu}
            >
              SERVICES
            </NavLink>
          </li>

        </ul>
      </div>
      <div className="info-right">
        <a href="tel:2149842522">214-984-2522</a>
      </div>
    </nav>
  );
}

export default NavigationMenu;