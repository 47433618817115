// src/components/ServicesPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ServicesPage.css';
import corporateTravelImage from '../assets/corporate-travel.jpg';
import airportTransfersImage from '../assets/airport-transfers.jpg';
import promChauffeurImage from '../assets/prom-chauffeur.jpg';
import sportingEventsImage from '../assets/sporting-events.jpg';
import privateChauffeurImage from '../assets/private-chauffeur.jpg';

function ServicesPage() {
  return (
    <div className="services-page-page">
      <h1 className="services-page-title">Our Exclusive Services</h1>

      {/* Corporate Travel */}
      <section className="services-page-section">
        <div className="services-page-content">
          <h2 className="services-page-heading">Corporate Travel</h2>
          <p className="services-page-description">
            Experience unparalleled professionalism with our bespoke transportation solutions tailored for discerning business leaders. Whether it's a crucial meeting, a corporate event, or executive travel, our dedicated chauffeurs ensure timely and comfortable journeys, allowing you to focus on what truly matters.
          </p>
          <Link to="/book" className="services-page-bookNowLink">
            Book Now
          </Link>
        </div>
        <div className="services-page-imageContainer">
          <img src={corporateTravelImage} alt="Corporate Travel" className="services-page-image" loading="lazy" />
        </div>
      </section>

      {/* Airport Transfers */}
      <section className="services-page-section services-page-sectionReverse">
        <div className="services-page-content">
          <h2 className="services-page-heading">Airport Transfers</h2>
          <p className="services-page-description">
            Arrive and depart with elegance. Our punctual and luxurious airport transfers redefine your travel experience, providing seamless connections to and from the airport. Enjoy a stress-free journey with our reliable service, ensuring you reach your destination relaxed and on time.
          </p>
          <Link to="/book" className="services-page-bookNowLink">
            Book Now
          </Link>
        </div>
        <div className="services-page-imageContainer">
          <img src={airportTransfersImage} alt="Airport Transfers" className="services-page-image" loading="lazy" />
        </div>
      </section>

      {/* Prom Chauffeur */}
      <section className="services-page-section">
        <div className="services-page-content">
          <h2 className="services-page-heading">Prom Chauffeur</h2>
          <p className="services-page-description">
            Make a grand entrance with our premium prom chauffeur services, ensuring a night of unforgettable elegance and style. Our professional drivers provide a safe and stylish ride, allowing you to enjoy your special evening without any worries.
          </p>
          <Link to="/book" className="services-page-bookNowLink">
            Book Now
          </Link>
        </div>
        <div className="services-page-imageContainer">
          <img src={promChauffeurImage} alt="Prom Chauffeur" className="services-page-image" loading="lazy" />
        </div>
      </section>

      {/* Sporting Events */}
      <section className="services-page-section services-page-sectionReverse">
        <div className="services-page-content">
          <h2 className="services-page-heading">Sporting Events</h2>
          <p className="services-page-description">
            Enjoy VIP treatment with our exclusive transportation services to your most anticipated sporting events. Whether you're attending a major league game or a local match, our comfortable and reliable rides ensure you arrive in style and on schedule.
          </p>
          <Link to="/book" className="services-page-bookNowLink">
            Book Now
          </Link>
        </div>
        <div className="services-page-imageContainer">
          <img src={sportingEventsImage} alt="Sporting Events" className="services-page-image" loading="lazy" />
        </div>
      </section>

      {/* Private Chauffeur */}
      <section className="services-page-section">
        <div className="services-page-content">
          <h2 className="services-page-heading">Private Chauffeur</h2>
          <p className="services-page-description">
            Indulge in personalized chauffeur services, offering privacy, comfort, and sophistication for any occasion. From intimate dinners to grand celebrations, our dedicated chauffeurs cater to your every need, ensuring a luxurious and seamless transportation experience.
          </p>
          <Link to="/book" className="services-page-bookNowLink">
            Book Now
          </Link>
        </div>
        <div className="services-page-imageContainer">
          <img src={privateChauffeurImage} alt="Private Chauffeur" className="services-page-image" loading="lazy" />
        </div>
      </section>
    </div>
  );
}

export default ServicesPage;