// src/pages/CadillacEscalade.js
import React from 'react';
import '../styles/VehiclePage.css';
import escaladeMain from '../assets/vehicles/cadillac-escalade/escalade-main.jpg';
import escalade1 from '../assets/vehicles/cadillac-escalade/escalade-1.jpg';
import escalade2 from '../assets/vehicles/cadillac-escalade/escalade-2.jpg';
import escalade3 from '../assets/vehicles/cadillac-escalade/escalade-3.jpg';
import escalade4 from '../assets/vehicles/cadillac-escalade/escalade-4.jpg';

function CadillacEscalade() {
  return (
    <div className="vehicle-page">
      <h1 className="vehicle-title">Cadillac Escalade</h1>
      <img src={escaladeMain} alt="Cadillac Escalade" className="vehicle-image" />
      <div className="vehicle-content">
        <p>
          The Cadillac Escalade is a bold statement of luxury and power.
          With seating for up to <strong>6 passengers</strong>, it's designed
          for those who require space without compromising on style or comfort.
        </p>
        <h2>Features</h2>
        <ul>
          <li>Luxurious seating with massage function</li>
          <li>Industry-leading 38-inch curved OLED display</li>
          <li>Advanced safety and driver-assistance features</li>
          <li>Premium AKG Studio Reference sound system</li>
          <li>Spacious cabin with ambient lighting</li>
        </ul>
        <h2>Why Choose the Cadillac Escalade?</h2>
        <p>
          The Escalade offers unmatched presence and sophistication.
          Ideal for group travel, it ensures every passenger experiences
          first-class amenities. Elevate your journey with this iconic SUV.
        </p>
        <div className="vehicle-gallery">
          <h2>Gallery</h2>
          <div className="gallery-images">
            <div className="gallery-item">
              <img src={escalade1} alt="Escalade Interior 1" />
            </div>
            <div className="gallery-item">
              <img src={escalade2} alt="Escalade Interior 2" />
            </div>
            <div className="gallery-item">
              <img src={escalade3} alt="Escalade Interior 3" />
            </div>
            <div className="gallery-item">
              <img src={escalade4} alt="Escalade Interior 4" />
            </div>
            {/* Add more images as needed */}
          </div>
        </div>
        <div className="book-now-container">
          <a href="/booking?vehicle=Cadillac%20Escalade" className="book-now-button2">
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default CadillacEscalade;
