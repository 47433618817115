// src/pages/GMCYukonDenali.js
import React from 'react';
import '../styles/VehiclePage.css';
import yukonMain from '../assets/vehicles/gmc-yukon-denali/yukon-main.jpg';
import yukon1 from '../assets/vehicles/gmc-yukon-denali/yukon-1.jpg';
import yukon2 from '../assets/vehicles/gmc-yukon-denali/yukon-2.jpg';
import yukon3 from '../assets/vehicles/gmc-yukon-denali/yukon-3.jpg';
import yukon4 from '../assets/vehicles/gmc-yukon-denali/yukon-4.jpg';

function GMCYukonDenali() {
  return (
    <div className="vehicle-page">
      <h1 className="vehicle-title">GMC Yukon Denali</h1>
      <img src={yukonMain} alt="GMC Yukon Denali" className="vehicle-image" />
      <div className="vehicle-content">
        <p>
          The GMC Yukon Denali offers a perfect blend of luxury and capability.
          With seating for up to <strong>7 passengers</strong>, it's ideal for
          larger groups seeking premium comfort and style.
        </p>
        <h2>Features</h2>
        <ul>
          <li>Premium leather seating with heated and ventilated seats</li>
          <li>Advanced infotainment with navigation and Bose® audio</li>
          <li>Rear-seat media system with dual screens</li>
          <li>Adaptive suspension for a smooth ride</li>
          <li>Spacious interior with ample cargo space</li>
        </ul>
        <h2>Why Choose the GMC Yukon Denali?</h2>
        <p>
          The Yukon Denali is perfect for clients who need extra space without
          sacrificing luxury. Its upscale amenities and roomy interior make it
          a top choice for business groups and families alike.
        </p>
        <div className="vehicle-gallery">
          <h2>Gallery</h2>
          <div className="gallery-images">
            <div className="gallery-item">
              <img src={yukon1} alt="Yukon Denali Interior 1" />
            </div>
            <div className="gallery-item">
              <img src={yukon2} alt="Yukon Denali Interior 2" />
            </div>
            <div className="gallery-item">
              <img src={yukon3} alt="Yukon Denali Interior 3" />
            </div>
            <div className="gallery-item">
              <img src={yukon4} alt="Yukon Denali Interior 4" />
            </div>
            {/* Add more images as needed */}
          </div>
        </div>
        <div className="book-now-container">
          <a href="/booking?vehicle=GMC%20Yukon%20Denali" className="book-now-button2">
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default GMCYukonDenali;
